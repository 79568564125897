const actions = {
  SET_STATE: 'patients/SET_STATE',
  GET_LAB: 'patients/GET_LAB',
  GET_DOCTORS: 'patients/GET_DOCTORS',
  GET_ACCESS_TO_PATIENT: 'patients/GET_ACCESS_TO_PATIENT',
  GET_PROCEDURE_DOCTORS: 'patients/GET_PROCEDURE_DOCTORS',
  GET_PATIENTS: 'patients/GET_PATIENTS',
  GET_ELECTRODES: 'patients/GET_ELECTRODES',
  GET_PROBES: 'patients/GET_PROBES',
  GET_PDF: 'patients/GET_PDF',
  GET_PATIENT: 'patients/GET_PATIENT',
  DELETE_PATIENT: 'patients/DELETE_PATIENT',
  CREATE_PATIENT: 'patients/CREATE_PATIENT',
  SEARCH_PATIENTS: 'patients/SEARCH_PATIENTS',
  GET_COUNTIES: 'patients/GET_COUNTIES',
  GET_CITIES: 'patients/GET_CITIES',
  UPDATE_PATIENT: 'patients/UPDATE_PATIENT',
  DELETE_LAB: 'patients/DELETE_LAB',
}

export default actions
