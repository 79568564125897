import { all, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import client from '../../services/axios/client'
import actions from './actions'

export function* GET_REQUESTS() {
  yield put({
    type: 'requests/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield client.get(`patients/requests`)
  if (response && response.status === 200) {
    yield put({
      type: 'requests/SET_STATE',
      payload: {
        list: response.data,
      },
    })
  }
  yield put({
    type: 'requests/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* APPROVE_ACCESS_TO_PATIENT({ payload }) {
  yield put({
    type: 'requests/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield client.put(`patients/requests/approve`, payload)
  if (response) {
    yield put({
      type: 'requests/GET_REQUESTS',
    })
    notification.success({
      message: 'Success!',
      description: 'Accesul a fost aprobat cu success',
    })
  } else {
    notification.error({
      message: 'Failed',
      description: 'Nu s-a putut da acccept la cerere.',
    })
  }
  yield put({
    type: 'requests/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_REQUESTS, GET_REQUESTS),
    takeEvery(actions.APPROVE_ACCESS_TO_PATIENT, APPROVE_ACCESS_TO_PATIENT),
  ])
}
