const actions = {
  SET_STATE: 'auth/SET_STATE',
  LOGIN: 'auth/LOGIN',
  REGISTER: 'auth/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'auth/LOAD_CURRENT_ACCOUNT',
  RESET_PASSWORD: 'auth/RESET_PASSWORD',
  LOGOUT: 'auth/LOGOUT',
}

export default actions
