import apiClient from 'services/axios';

const companiesApi = {
  getCompanies(params) {
    return apiClient.get('companies',{ params })
      .then((response) => response)
      .catch(err => {
        console.log(err)
        return err;
      });
  },
  getCompany(id) {
    return apiClient.get(`companies/${id}` )
      .then((response) => response)
      .catch(err => {
        console.log(err)
        return err;
      });
  },
  createCompany(data) {
    return apiClient.post('companies',data)
      .then((response) => response)
      .catch(err => {
        console.log(err)
        return err;
      });
  },
  updateCompany(data) {
    return apiClient.put('companies',data)
      .then((response) => response)
      .catch(err => {
        console.log(err)
        return err;
      });
  },
  deleteCompany(id) {
    return apiClient.delete (`companies/${id}`)
      .then((response) => response)
      .catch(err => {
        console.log(err)
        return err;
      });
  },

};

export default companiesApi;
