import apiClient from 'services/axios'

const usersApi = {
  getRoles(params) {
    return apiClient
      .get('users/roles', { params })
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },

  getPublicUser(code) {
    return apiClient
      .get(`auth/invite/${code}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getUsers(params) {
    return apiClient
      .get('users', { params })
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getUser(id) {
    return apiClient
      .get(`users/${id}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  createUser(data) {
    return apiClient
      .post('users', data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  updateUser(data) {
    return apiClient
      .put('users', data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  deleteUser(id) {
    return apiClient
      .delete(`users/${id}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
}

export default usersApi
