const actions = {
  SET_STATE: 'labs/SET_STATE',

  GET_LAB: 'labs/GET_LAB',
  GET_LABS: 'labs/GET_LABS',
  CREATE_LAB: 'labs/CREATE_LAB',
  UPDATE_LAB: 'labs/UPDATE_LAB',
  DELETE_LAB: 'labs/DELETE_LAB',
}

export default actions
