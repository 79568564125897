import { notification } from 'antd';
import { history } from 'index';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import labsApi from "services/labsApi"

import actions from './actions';

export function* GET_LAB({ payload }) {
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(labsApi.getLab, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'labs/SET_STATE',
      payload: {
        lab: response.data,
      },
    })
  }
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_LABS({ payload }) {
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(labsApi.getLabs, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'labs/SET_STATE',
      payload: {
        list: response.data,
      },
    })
  }
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_LAB({ payload }) {
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(labsApi.createLab, payload)
  if (response && response.status === 200) {
    yield history.push('/labs')
    notification.success({
      message: 'Success!',
      description: 'Lab created succesfully!',
    })
  }
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_LAB({ payload }) {
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(labsApi.updateLab, payload)
  if (response && response.status === 200) {
    yield history.push('/labs')
    notification.success({
      message: 'Success!',
      description: 'Lab updated succesfully!',
    })
  }
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_LAB({ payload }) {
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(labsApi.deleteLab, payload.id)
  if (response && response.status === 200) {
    notification.success({
      message: 'Success!',
      description: 'Lab removed succesfully!',
    })

    yield put({
      type: 'labs/GET_LABS',
      payload,
    })
  }
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LABS, GET_LABS),
    takeEvery(actions.CREATE_LAB, CREATE_LAB),
    takeEvery(actions.GET_LAB, GET_LAB),
    takeEvery(actions.UPDATE_LAB, UPDATE_LAB),
    takeEvery(actions.DELETE_LAB, DELETE_LAB),
  ])
}
