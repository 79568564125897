const actions = {
  SET_STATE: 'companies/SET_STATE',

  GET_COMPANY: 'companies/GET_COMPANY',
  GET_COMPANIES: 'companies/GET_COMPANIES',
  CREATE_COMPANY: 'companies/CREATE_COMPANY',
  UPDATE_COMPANY: 'companies/UPDATE_COMPANY',
  DELETE_COMPANY: 'companies/DELETE_COMPANY',
}

export default actions
