import { notification } from 'antd'
import { history } from 'index'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import labsApi from 'services/labsApi'

import client from '../../services/axios/client'
import actions from './actions'

export function* GET_LAB({ payload }) {
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(labsApi.getLab, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'labs/SET_STATE',
      payload: {
        lab: response.data,
      },
    })
  }
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_DOCTORS() {
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield client.get(`doctors`)
  if (response && response.status === 200) {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        doctorsList: response.data,
      },
    })
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_PATIENT({ payload }) {
  try {
    const { id } = payload

    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield client.get(`patients/${id}`)
    if (response && response.status === 200) {
      yield put({
        type: 'patients/SET_STATE',
        payload: {
          patient: response.data,
          listMeta: response.meta,
        },
      })
    } else {
      notification.error({
        message: 'Failed',
        description: 'Pacientul nu exista',
      })
      yield history.push(`/patients`)
    }
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        error,
      },
    })
  }
}

export function* GET_ACCESS_TO_PATIENT({ payload }) {
  try {
    const { patient } = payload
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield client.post(`patients/${patient.id}/access`, patient)
    if (response && response.status === 200) {
      yield put({
        type: 'patients/SET_STATE',
        payload: {
          patient: response.data,
        },
      })

      yield put({
        type: 'patients/SEARCH_PATIENTS',
        payload: {
          cnp: payload.cnp,
        },
      })

      notification.success({
        message: 'Success',
        description: 'Cererea dosarului a fost trimisa',
      })
    } else {
      notification.error({
        message: 'Failed',
        description: 'Nu s-a putut crea cererea',
      })
      yield history.push(`/patients`)
    }
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        error,
      },
    })
  }
}

export function* GET_PATIENTS({ payload }) {
  try {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield client.get(`patients`, payload.filters)
    if (response && response.status === 200) {
      yield put({
        type: 'patients/SET_STATE',
        payload: {
          list: response.data,
        },
      })
    }
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        error,
      },
    })
  }
}

export function* DELETE_PATIENT({ payload }) {
  try {

    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield client.delete(`patients/${payload}`)
    if (response && response.status === 200) {
      notification.success({
        message: 'Success!',
        description: 'Patient removed succesfully!',
      })
      yield put({
        type: 'patients/GET_PATIENTS',
        payload: {
          patient: response.data,
          listMeta: response.meta,
        },
      })
    } else {
      notification.error({
        message: 'Failed',
        description: 'Pacientul nu exista',
      })
      yield history.push(`/patients`)
    }
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        error,
      },
    })
  }
}

export function* GET_PROCEDURE_DOCTORS({ payload }) {
  try {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield client.get(`doctors/procedure/${payload.id}`)
    if (response && response.status === 200) {
      yield put({
        type: 'patients/SET_STATE',
        payload: {
          procedureDoctors: response.data,
        },
      })
    }
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        error,
      },
    })
  }
}

export function* GET_PROBES({ payload }) {
  try {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield client.get(`followups/${payload.id}/probes`)
    if (response && response.status === 200) {
      yield put({
        type: 'patients/SET_STATE',
        payload: {
          probes: response.data,
        },
      })
    }
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        error,
      },
    })
  }
}

export function* GET_PDF({ payload }) {
  try {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield client.get(`patients/${payload.patientId}/pdf/${payload.procedureId}`)
    if (response && response.status === 200) {
      window.open(response.data.url)
    }
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        error,
      },
    })
  }
}

export function* GET_ELECTRODES({ payload }) {
  try {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield client.get(`procedures/${payload.id}/electrodes`)
    if (response && response.status === 200) {
      yield put({
        type: 'patients/SET_STATE',
        payload: {
          electrodes: response.data,
        },
      })
    }
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        error,
      },
    })
  }
}

export function* GET_COUNTIES() {
  try {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield client.get(`locations/counties`)
    if (response && response.status === 200) {
      yield put({
        type: 'patients/SET_STATE',
        payload: {
          countiesList: response.data,
        },
      })
    }
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        error,
      },
    })
  }
}

export function* GET_CITIES({ payload }) {
  try {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield client.get(`locations/cities/${payload.id}`)
    if (response && response.status === 200) {
      yield put({
        type: 'patients/SET_STATE',
        payload: {
          citiesList: response.data,
        },
      })
    }
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        error,
      },
    })
  }
}

export function* SEARCH_PATIENTS({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield client.post(`patients/search`, payload)

  if (response && response.status === 200) {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        searchList: response.data,
      },
    })

    // yield history.push(`/patients/update/${response.data.id}`)
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_PATIENT({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield client.post(`patients`, payload)

  if (response && response.status === 200) {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        patient: response.data,
      },
    })

    yield history.push(`/patients/update/${response.data.id}`)
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_PATIENT({ payload }) {
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield client.put(`patients`, payload)
  if (response && response.status === 200) {
    notification.success({
      message: 'Success!',
      description: 'Datele pacientului au fost salvate!',
    })

    yield put({
      type: 'patients/GET_PATIENT',
      payload: {
        id: payload.id,
      },
    })
  } else {
    console.log(response)
    notification.error({
      message: 'Failed',
      description: 'Datele nu au fost salvate.',
    })
  }
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_LAB({ payload }) {
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(labsApi.deleteLab, payload.id)
  if (response && response.status === 200) {
    notification.success({
      message: 'Success!',
      description: 'Lab removed succesfully!',
    })

    yield put({
      type: 'labs/GET_LABS',
      payload,
    })
  }
  yield put({
    type: 'labs/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PATIENTS, GET_PATIENTS),
    takeEvery(actions.GET_PATIENT, GET_PATIENT),
    takeEvery(actions.DELETE_PATIENT, DELETE_PATIENT),
    takeEvery(actions.GET_DOCTORS, GET_DOCTORS),
    takeEvery(actions.GET_ACCESS_TO_PATIENT, GET_ACCESS_TO_PATIENT),
    takeEvery(actions.GET_PROCEDURE_DOCTORS, GET_PROCEDURE_DOCTORS),
    takeEvery(actions.GET_ELECTRODES, GET_ELECTRODES),
    takeEvery(actions.GET_PROBES, GET_PROBES),
    takeEvery(actions.GET_PDF, GET_PDF),
    takeEvery(actions.CREATE_PATIENT, CREATE_PATIENT),
    takeEvery(actions.SEARCH_PATIENTS, SEARCH_PATIENTS),
    takeEvery(actions.GET_COUNTIES, GET_COUNTIES),
    takeEvery(actions.GET_CITIES, GET_CITIES),
    takeEvery(actions.GET_LAB, GET_LAB),
    takeEvery(actions.UPDATE_PATIENT, UPDATE_PATIENT),
    takeEvery(actions.DELETE_LAB, DELETE_LAB),
  ])
}
