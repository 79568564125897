import { ConnectedRouter } from 'connected-react-router'
import Layout from 'layouts'
import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

const routes = [
  // Etichub pages
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },

  // Emails
  {
    path: '/emails',
    Component: lazy(() => import('pages/emails/list')),
    exact: true,
  },
  {
    path: '/emails/create',
    Component: lazy(() => import('pages/emails/create')),
    exact: true,
  },
  {
    path: '/emails/update/:id',
    Component: lazy(() => import('pages/emails/update')),
    exact: true,
  },
  {
    path: '/requests',
    Component: lazy(() => import('pages/requests/list')),
    exact: true,
  },
  // Labs
  {
    path: '/labs',
    Component: lazy(() => import('pages/labs/list')),
    exact: true,
  },
  {
    path: '/labs/create',
    Component: lazy(() => import('pages/labs/create')),
    exact: true,
  },
  {
    path: '/labs/update/:id',
    Component: lazy(() => import('pages/labs/update')),
    exact: true,
  },

  // Volunteers
  {
    path: '/patients',
    Component: lazy(() => import('pages/patients/list')),
    exact: true,
  },
  {
    path: '/patients/create',
    Component: lazy(() => import('pages/patients/create')),
    exact: true,
  },
  {
    path: '/patients/update/:id',
    Component: lazy(() => import('pages/patients/create')),
    exact: true,
  },
  // Companies
  {
    path: '/companies',
    Component: lazy(() => import('pages/companies/list')),
    exact: true,
  },
  {
    path: '/companies/create',
    Component: lazy(() => import('pages/companies/create')),
    exact: true,
  },
  {
    path: '/companies/update/:id',
    Component: lazy(() => import('pages/companies/update')),
    exact: true,
  },
  // Studies
  {
    path: '/studies',
    Component: lazy(() => import('pages/studies/list')),
    exact: true,
  },
  {
    path: '/studies/create',
    Component: lazy(() => import('pages/studies/create')),
    exact: true,
  },
  {
    path: '/studies/update/:id',
    Component: lazy(() => import('pages/studies/update')),
    exact: true,
  },
  // Tests
  {
    path: '/tests',
    Component: lazy(() => import('pages/tests/list')),
    exact: true,
  },
  {
    path: '/tests/create',
    Component: lazy(() => import('pages/tests/create')),
    exact: true,
  },
  {
    path: '/tests/update/:id',
    Component: lazy(() => import('pages/tests/update')),
    exact: true,
  },
  // Datas
  {
    path: '/data',
    Component: lazy(() => import('pages/data/list')),
    exact: true,
  },
  // {
  //   path: '/tests/create',
  //   Component: lazy(() => import('pages/datas/create')),
  //   exact: true,
  // },
  // {
  //   path: '/tests/update/:id',
  //   Component: lazy(() => import('pages/datas/update')),
  //   exact: true,
  // },
  // Reports
  {
    path: '/reports',
    Component: lazy(() => import('pages/reports/list')),
    exact: true,
  },
  {
    path: '/reports/devices',
    Component: lazy(() => import('pages/reports/components/devicesReports')),
    exact: true,
  },
  {
    path: '/reports/finances',
    Component: lazy(() => import('pages/reports/components/financesReports')),
    exact: true,
  },
  // {
  //   path: '/tests/create',
  //   Component: lazy(() => import('pages/datas/create')),
  //   exact: true,
  // },
  // {
  //   path: '/tests/update/:id',
  //   Component: lazy(() => import('pages/datas/update')),
  //   exact: true,
  // },
  // Appointments
  {
    path: '/appointments',
    Component: lazy(() => import('pages/appointments/list')),
    exact: true,
  },
  // {
  //   path: '/tests/create',
  //   Component: lazy(() => import('pages/datas/create')),
  //   exact: true,
  // },
  // {
  //   path: '/tests/update/:id',
  //   Component: lazy(() => import('pages/datas/update')),
  //   exact: true,
  // },
  // Users
  {
    path: '/users',
    Component: lazy(() => import('pages/admin/users/list')),
    exact: true,
  },
  {
    path: '/users/create',
    Component: lazy(() => import('pages/admin/users/create')),
    exact: true,
  },
  {
    path: '/users/update/:id',
    Component: lazy(() => import('pages/admin/users/update')),
    exact: true,
  },

  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/logout',
    // Component: lazy(() => import('pages/auth')),
    exact: false,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register/:code',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  // {
  //   path: '/auth/lockscreen',
  //   Component: lazy(() => import('pages/auth/lockscreen')),
  //   exact: true,
  // },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/patients" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
