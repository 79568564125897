import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import auth from './auth/reducers'
import companies from './companies/reducers'
import labs from './labs/reducers'
import menu from './menu/reducers'
import patients from './patients/reducers'
import settings from './settings/reducers'
import users from './users/reducers'
import reports from './reports/reducers'
import requests from './requests/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    menu,
    requests,
    settings,
    users,
    companies,
    labs,
    patients,
    reports,
  })
