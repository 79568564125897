import { notification } from 'antd'
import { history } from 'index'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import usersApi from 'services/usersApi'

import actions from './actions'

export function* GET_ROLES({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loadingRoles: true,
    },
  })
  const response = yield call(usersApi.getRoles, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        roles: response.data,
      },
    })
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loadingRoles: false,
    },
  })
}

export function* GET_PUBLIC_USER({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { code } = payload
  const response = yield call(usersApi.getPublicUser, code)
  if (response && response.status === 200) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        user: response.data,
      },
    })
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_USER({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(usersApi.getUser, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        user: response.data,
      },
    })
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_USERS({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(usersApi.getUsers, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        list: response.data,
      },
    })
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_USER({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(usersApi.createUser, payload)
  if (response && response.status === 200) {
    yield history.push('/users')
    notification.success({
      message: 'Success!',
      description: 'User created succesfully!',
    })
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_USER({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(usersApi.updateUser, payload)
  if (response && response.status === 200) {
    yield history.push('/users')
    notification.success({
      message: 'Success!',
      description: 'User updated succesfully!',
    })
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_USER({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(usersApi.deleteUser, payload.id)
  if (response && response.status === 200) {
    notification.success({
      message: 'Success!',
      description: 'User removed succesfully!',
    })

    yield put({
      type: 'users/GET_USERS',
      payload,
    })
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ROLES, GET_ROLES),
    takeEvery(actions.GET_USERS, GET_USERS),
    takeEvery(actions.CREATE_USER, CREATE_USER),
    takeEvery(actions.GET_USER, GET_USER),
    takeEvery(actions.GET_PUBLIC_USER, GET_PUBLIC_USER),
    takeEvery(actions.UPDATE_USER, UPDATE_USER),
    takeEvery(actions.DELETE_USER, DELETE_USER),
  ])
}
