import { all, call, put, takeEvery } from 'redux-saga/effects'

import reportsApi from '../../services/reportsApi'
import actions from './actions'

export function* GET_FINANCE_REPORTS() {
  yield put({
    type: 'reports/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(reportsApi.getFinanceReports)
  if (response && response.status === 200) {
    yield put({
      type: 'reports/SET_STATE',
      payload: {
        financeReports: response.data,
      },
    })
  }
  yield put({
    type: 'reports/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_DEVICE_REPORTS() {
  yield put({
    type: 'reports/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(reportsApi.getDeviceReports)
  if (response && response.status === 200) {
    yield put({
      type: 'reports/SET_STATE',
      payload: {
        deviceReports: response.data,
      },
    })
  }
  yield put({
    type: 'reports/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DOWNLOAD_DEVICE_REPORT({ payload }) {
  yield put({
    type: 'reports/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(reportsApi.downloadDeviceReport, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'reports/SET_STATE',
      payload: {
        deviceReports: response.data,
      },
    })
    if (response && response.data && response.data.url) {
      window.location = response.data.url
    }
  }
  yield put({
    type: 'reports/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DOWNLOAD_FINANCE_REPORT({ payload }) {
  yield put({
    type: 'reports/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(reportsApi.downloadFinanceReport, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'reports/SET_STATE',
      payload: {
        deviceReports: response.data,
      },
    })
    if (response && response.data && response.data.url) {
      window.location = response.data.url
    }
  }
  yield put({
    type: 'reports/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_FINANCE_REPORTS, GET_FINANCE_REPORTS),
    takeEvery(actions.GET_DEVICE_REPORTS, GET_DEVICE_REPORTS),
    takeEvery(actions.DOWNLOAD_DEVICE_REPORT, DOWNLOAD_DEVICE_REPORT),
    takeEvery(actions.DOWNLOAD_FINANCE_REPORT, DOWNLOAD_FINANCE_REPORT),
  ])
}
