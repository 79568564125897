import { notification } from 'antd';
import { history } from 'index';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import companiesApi from "services/companiesApi"

import actions from './actions';

export function* GET_COMPANY({ payload }) {
  yield put({
    type: 'companies/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(companiesApi.getCompany, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'companies/SET_STATE',
      payload: {
        company: response.data,
      },
    })
  }
  yield put({
    type: 'companies/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_COMPANIES({ payload }) {
  yield put({
    type: 'companies/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(companiesApi.getCompanies, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'companies/SET_STATE',
      payload: {
        list: response.data,
      },
    })
  }
  yield put({
    type: 'companies/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_COMPANY({ payload }) {
  yield put({
    type: 'companies/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(companiesApi.createCompany, payload)
  if (response && response.status === 200) {
    yield history.push('/companies')
    notification.success({
      message: 'Success!',
      description: 'Company created succesfully!',
    })
  }
  yield put({
    type: 'companies/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_COMPANY({ payload }) {
  yield put({
    type: 'companies/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(companiesApi.updateCompany, payload)
  if (response && response.status === 200) {
    yield history.push('/companies')
    notification.success({
      message: 'Success!',
      description: 'Company updated succesfully!',
    })
  }
  yield put({
    type: 'companies/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_COMPANY({ payload }) {
  yield put({
    type: 'companies/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(companiesApi.deleteCompany, payload.id)
  if (response && response.status === 200) {
    notification.success({
      message: 'Success!',
      description: 'Company removed succesfully!',
    })

    yield put({
      type: 'companies/GET_COMPANIES',
      payload,
    })
  }
  yield put({
    type: 'companies/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_COMPANIES, GET_COMPANIES),
    takeEvery(actions.CREATE_COMPANY, CREATE_COMPANY),
    takeEvery(actions.GET_COMPANY, GET_COMPANY),
    takeEvery(actions.UPDATE_COMPANY, UPDATE_COMPANY),
    takeEvery(actions.DELETE_COMPANY, DELETE_COMPANY),
  ])
}
