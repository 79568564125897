/* eslint-disable radix */
import { SearchOutlined } from '@ant-design/icons'
import { Input, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import style from './style.module.scss'

let searchInput = null

const Search = ({ intl: { formatMessage } }) => {
  const dispatch = useDispatch()
  const [showSearch, setShowSearch] = useState(false)
  const [searchText, setSearchText] = useState('')

  const patientsList = useSelector(state => (state.patients && state.patients.searchList) || [])
  const userRole = useSelector(state => (state.auth && state.auth.role) || [])
  const centerId = useSelector(state => (state.auth && state.auth.centerId) || [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  const showLiveSearch = () => {
    setShowSearch(true)
    setTimeout(() => {
      searchInput.focus()
    }, 100)
  }

  const changeSearchText = e => {
    setSearchText(e.target.value)
  }

  const hideLiveSearch = () => {
    searchInput.blur()
    setShowSearch('')
    setSearchText('')
  }

  const handleKeyDown = event => {
    if (showSearch) {
      const key = event.keyCode.toString()
      if (userRole.type === 'admin') {
        dispatch({
          type: 'users/GET_USERS',
          payload: searchText,
        })
      }
      if (key === '27') {
        hideLiveSearch()
      }
    }
  }

  const createNewPatient = () => {
    dispatch({
      type: 'patients/CREATE_PATIENT',
      payload: {},
    })
    hideLiveSearch()
  }

  const triggerGetPatientAccess = patient => {
    dispatch({
      type: 'patients/GET_ACCESS_TO_PATIENT',
      payload: {
        patient,
        cnp: parseInt(searchText),
      },
    })
    dispatch({
      type: 'requests/GET_REQUESTS',
    })
  }

  const handleNode = node => {
    searchInput = node
  }

  useEffect(() => {
    if (searchText) {
      dispatch({
        type: 'patients/SEARCH_PATIENTS',
        payload: {
          cnp: parseInt(searchText),
        },
      })
    }
  }, [searchText])

  return (
    <div className="d-inline-block mr-4">
      <Input
        className={style.extInput}
        placeholder="Caută pacient..."
        prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        style={{ width: 200 }}
        onFocus={showLiveSearch}
      />
      <div
        className={`${
          showSearch ? `${style.livesearch} ${style.livesearchVisible}` : style.livesearch
        }`}
        id="livesearch"
      >
        <button className={style.close} type="button" onClick={hideLiveSearch}>
          <i className="icmn-cross" />
        </button>
        <div className="container-fluid">
          <div className={style.wrapper}>
            <input
              type="search"
              className={style.searchInput}
              value={searchText}
              onChange={changeSearchText}
              id="livesearchInput"
              placeholder="Cauta..."
              ref={handleNode}
            />
            {!searchText && (
              <div className={style.results}>
                <div className={style.resultsTitle}>
                  <span>Fara rezultat...</span>
                </div>
              </div>
            )}
            {searchText && (
              <div className={style.results}>
                {(patientsList &&
                  patientsList[0] &&
                  patientsList[0].length > 0 &&
                  patientsList[0].map((patient, index) => {
                    return (
                      <div className="row">
                        <div className={`col-lg-12 ${style.patientContainer}`}>
                          <Link
                            to={`patients/update/${patient.id}`}
                            style={{ color: '#283a5e' }}
                            onClick={() => hideLiveSearch()}
                          >
                            <div className={style.resultContent}>
                              <div className={style.resultThumb}>#{index + 1}</div>
                              <div className={style.result}>
                                <div className={style.resultText}> {patient.name}</div>
                                <div className={style.resultSource}>{patient.cnp}</div>
                              </div>
                            </div>
                          </Link>
                          {(!(centerId !== patient.centerId) || !patient.hasRequest) && (
                            <Button type="primary" onClick={() => triggerGetPatientAccess(patient)}>
                              Cerere Dosar
                            </Button>
                          )}
                        </div>
                      </div>
                    )
                  })) || (
                  <div className={style.results}>
                    <div className={style.resultsTitle}>
                      <span>Nu s-au gasit pacienti</span>
                      <button type="button" onClick={createNewPatient}>
                        Adauga Pacient
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Search)
