import apiClient from 'services/axios'

const labsApi = {
  getLabs(params) {
    return apiClient
      .get('centers', { params })
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getLab(id) {
    return apiClient
      .get(`centers/${id}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  createLab(data) {
    return apiClient
      .post('centers', data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  updateLab(data) {
    return apiClient
      .put('centers', data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  deleteLab(id) {
    return apiClient
      .delete(`centers/${id}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
}

export default labsApi
