import { all } from 'redux-saga/effects'

import auth from './auth/sagas'
import companies from './companies/sagas'
import labs from './labs/sagas'
import menu from './menu/sagas'
import patients from './patients/sagas'
import settings from './settings/sagas'
import users from './users/sagas'
import reports from './reports/sagas'
import requests from './requests/sagas'

export default function* rootSaga() {
  yield all([
    auth(),
    requests(),
    menu(),
    settings(),
    users(),
    companies(),
    labs(),
    patients(),
    reports(),
  ])
}
