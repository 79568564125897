import React from 'react'
import { Tabs } from 'antd'
import style from './style.module.scss'

const { TabPane } = Tabs

const List2 = () => {
  return (
    <div>
      <Tabs className={`${style.tabs} kit-tabs-bordered`} defaultActiveKey="1">
        <TabPane tab="Alerts" key="1">
          <div className="text-gray-6">
            <ul className="list-unstyled">
              <li className="mb-3">
                <div className={style.head}>
                  <p className={style.title}>
                    Example notification 1:
                    <strong className="text-black"> New</strong>
                  </p>
                  <time className={style.time}>5 min ago</time>
                </div>
                <p>Example notification description.</p>
              </li>
              <li className="mb-3">
                <div className={style.head}>
                  <p className={style.title}>
                    Example notification 2:
                  </p>
                  <time className={style.time}>6 min ago</time>
                </div>
                <p>Example notification description.</p>
              </li>
              <li className="mb-3">
                <div className={style.head}>
                  <p className={style.title}>
                    Example notification 3:
                  </p>
                  <time className={style.time}>36 min ago</time>
                </div>
                <p>Example notification description.</p>
              </li>
            </ul>
          </div>
        </TabPane>
        {/* <TabPane tab="Events" key="2"> */}
        {/*  <div className="text-center mb-3 py-4 bg-light rounded">No Events</div> */}
        {/* </TabPane> */}
        {/* <TabPane tab="Actions" key="3"> */}
        {/*  <div className="text-center mb-3 py-4 bg-light rounded">No Actions</div> */}
        {/* </TabPane> */}
      </Tabs>
    </div>
  )
}

export default List2
