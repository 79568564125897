const actions = {
  SET_STATE: 'users/SET_STATE',
  GET_ROLES: 'users/GET_ROLES',

  GET_USER: 'users/GET_USER',
  GET_PUBLIC_USER: 'users/GET_PUBLIC_USER',

  GET_USERS: 'users/GET_USERS',
  CREATE_USER: 'users/CREATE_USER',
  UPDATE_USER: 'users/UPDATE_USER',
  DELETE_USER: 'users/DELETE_USER',
}

export default actions
